
export const imageR = {
    chest_crystal: "icons/Delve_Chests_Delvechests_delve_chest_9.png",
    chest_treasure: "icons/Explore_Explore_explore_step_05.png",
    boss_gray: "icons/Liveevents_Underspire_Keystone1.png",
    boss_green: "icons/Liveevents_Underspire_Keystone2.png",
    boss_blue: "icons/Liveevents_Underspire_Keystone3.png",
    boss_purple: "icons/Liveevents_Underspire_Keystone4.png",
    boss_yellow: "icons/Liveevents_Underspire_Keystone5.png",
    boss_red: "icons/Liveevents_Underspire_Keystone6.png",
    run: "icons/agile.png",
    gray: "icons/gray.png",
    green: "icons/green.png",
    blue: "icons/blue.png",
    purple: "icons/purple.png",
    yellow: "icons/yellow.png",
    red: "icons/red.png",
    gem: "icons/gem.png",
    ikun: "icons/ikun.gif",
}
